<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					class="font_Hsemibold"
					>{{ $t("calculator") }}</AppText
				>
			</div>
		</div>

		<div class="section__body">
			<div class="calculator__holder">
				<div class="calculator__column calculator__bg pa-20 mr-20">
					<div class="calculator__form-item mb-15">
						<form-label
							v-model="model.procedureType"
							:list="procedureType"
							component-type="dropdown"
							:title="this.$t('procedureType')"
							disabled="true"
							class="app-input--disabled"
						/>
					</div>

					<div class="calculator__form-item mb-15">
						<form-label
							v-model="model.role"
							:list="role"
							component-type="dropdown"
							:title="this.$t('role')"
						/>
					</div>

					<div class="calculator__form-item mb-15">
						<form-label v-model="model.number" component-type="input" :title="this.$t('startPrice')" />
					</div>

					<div class="calculator__form-item mb-15">
						<form-label
							v-model="model.number"
							component-type="input"
							:title="this.$t('enterTheAmountOfTheCommercialOffer')"
						/>
					</div>

					<div class="calculator__form-item">
						<form-label
							v-model="model.percentage"
							:disabled="isDisabledPercentage"
							:title="this.$t('selectThePercentageOfTheCollateral')"
						/>
					</div>

					<div class="mt-30 d-flex justify-content-right">
						<app-button theme="main" sides="20" height="40" fontSize="14" class="font_Hsemibold">{{
							$t("calculate")
						}}</app-button>
					</div>
				</div>

				<div class="calculator__column pa-20">
					<div class="d-flex justify-space-between w-100 mb-20">
						<AppText size="14" weight="500">{{ $t("collateralAmount") }}:</AppText>
						<AppText size="14" weight="600" class="font_Hsemibold">{{ getResultModel | price }}</AppText>
					</div>

					<div class="d-flex justify-space-between w-100 mb-30">
						<AppText size="14" weight="500">{{ $t("theAmountOfOperatorServices") }}:</AppText>
						<AppText size="14" weight="600" class="font_Hsemibold">{{ amountOfOperator | price }}</AppText>
					</div>

					<div class="d-flex justify-space-between w-100">
						<div class="mr-20">
							<AppText size="14" weight="600" class="mb-10 font_Hsemibold"
								>{{ $t("total") | toUpperCase }}:</AppText
							>

							<AppText v-if="model.number" size="20" weight="600" class="font_Hsemibold">{{
								(getResultModel + amountOfOperator).toFixed(2)
							}}</AppText>
						</div>

						<AppButton @click="clear()" theme="gray-light" sides="20" font-size="14" height="40">
							{{ $t("close") }}
						</AppButton>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import FormLabel from "../../components/shared-components/FormLabel";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppButton from "../../components/shared-components/AppButton";

export default {
	name: "index",
	components: { AppButton, BlockWrap, FormLabel, AppText },
	data() {
		return {
			model: {
				procedureType: "",
				role: "",
				percentage: "",
			},
			procedureType: [
				{
					name: this.$t("tender"),
					value: "1",
				},
				{
					name: this.$t("selection"),
					value: "2",
				},
				{
					name: this.$t("shop"),
					value: "3",
				},
				{
					name: this.$t("auction"),
					value: "4",
				},
			],
			role: [
				{
					name: "Бюджетный заказчик",
					value: "1",
				},
				{
					name: this.$t("provider"),
					value: "2",
				},
				{
					name: "Корпоративный заказчик",
					value: "3",
				},
			],
			percentage: [
				{
					name: "1%",
					value: "1",
				},
				{
					name: "2%",
					value: "2",
				},
				{
					name: "3%",
					value: "3",
				},
			],
			isDisabledPercentage: false,
		};
	},

	computed: {
		getResultModel() {
			if (isFinite(this.model.number)) {
				return +(this.model.number * (this.model.percentage / 100)).toFixed(2);
			}
		},
		amountOfOperator() {
			if (isFinite(this.model.number)) {
				let amountOperator = +(this.model.number * (0.1 / 100)).toFixed(2);
				// let amountForShop = +(this.model.number * (0.1 / 100)).toFixed(2);
				// if (amountOperator >= 10000 && (this.model.role == 1 || this.model.role == 3)) {
				// 	// alert("more than 10 000");
				// 	// amountOperator = 10000;
				// }
				// if (amountOperator >= 4000000 && this.model.role == 2) {
				// 	// alert("more than 4000000");
				// 	amountOperator = 4000000;
				// }
				// if (this.model.procedureType == 3 && (this.model.role == 1 || this.model.role == 2)) {
				// 	// alert("more than 4000000");
				// 	return amountForShop;
				// }
				return amountOperator;
			}
		},
	},

	watch: {
		"model.procedureType"(value) {
			// IF - ELSE
			switch (value) {
				case "1":
				case "2":
					this.model.percentage = "";
					this.isDisabledPercentage = false;
					break;

				case "3":
				case "4":
					if (this.model.role == 1) {
						this.model.percentage = 0;
						this.isDisabledPercentage = true;
					} else {
						this.model.percentage = "3";
						this.isDisabledPercentage = false;
					}
					break;

				default:
					break;
			}
		},
		"model.role"(value) {
			// IF - ELSE
			switch (value) {
				case "1":
					this.model.percentage = "";
					this.isDisabledPercentage = false;
					if (this.model.procedureType == 3 || this.model.procedureType == 4) {
						this.model.percentage = 0;
						this.isDisabledPercentage = true;
					}
					break;

				case "2":
				case "3":
					this.model.percentage = "";
					this.isDisabledPercentage = false;
					if (this.model.procedureType == 3 || this.model.procedureType == 4) {
						this.model.percentage = 3;
						this.isDisabledPercentage = false;
					}
					if (this.model.procedureType == 3) {
						this.model.percentage = 1;
						this.isDisabledPercentage = false;
					}
					break;

				default:
					break;
			}
		},
	},

	methods: {
		clear() {
			this.model.percentage = "";
			this.model.role = "";
			this.model.procedureType = "";
			this.model.number = "";
		},
	},
};
</script>

<style lang="scss" scoped>
.calculator {
	&__holder {
		display: flex;
		align-items: flex-start;
	}

	&__column {
		max-width: 416px;
		width: 100%;
		border-radius: 8px;
		background-color: #fff;
		&:last-child {
			box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
		}
	}

	&__bg {
		background: #fff;
	}
}
.page-container {
	padding-top: 45px;
	padding-bottom: 45px;
}
</style>

